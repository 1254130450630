<template>
    <div class="real-friend-meeting-confirm">
        <StackRouterHeaderBar :leftButton="leftButton" :leftButtonHandler="leftButtonHandler" />

        <main class="p-16">
            <h1 class="title m-b-40" v-html="title" />

            <div class="btns">
                <button
                    class="btn m-b-12"
                    :class="{ active: btn.active }"
                    v-for="(btn, idx) in buttons[step]"
                    :key="btn.label"
                    @click="onClick(btn, idx)"
                >
                    <p>{{ btn.label }}</p>
                    <i class="material-icons">chevron_right</i>
                </button>
            </div>
        </main>
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar'
import realFriendService from '@/services/realfriend-meeting'

export default {
    name: 'RealFriendMeetingConfirmPage',
    components: { StackRouterHeaderBar },
    props: {
        chat: Object,
    },
    data: () => ({
        step: 0,
        refundCnt: null,
        buttons: {
            0: [
                {
                    label: '네, 만났어요!',
                    active: false,
                },
                {
                    label: '만나지 않았어요',
                    active: false,
                },
            ],
            1: [
                {
                    label: '네, 환급해 주세요',
                    active: false,
                },
                {
                    label: '아니요, 만날 예정이에요!',
                    active: false,
                },
            ],
        },
    }),
    computed: {
        title() {
            return this.step === 0
                ? `${this.$nameOrNick(this.chat.user)}님 친구와<br />만남을 가지셨나요?`
                : '환급 신청을 하시겠어요?'
        },
        leftButton() {
            if (this.step === 1) return 'back'

            return 'close'
        },
        introduce() {
            return this.chat.introduce
        },
    },
    methods: {
        async init() {
            const {
                dating: { refund_cnt: refundCnt },
            } = await realFriendService.checkRefundCount(this.introduce.id, this.introduce.request.id)
            this.refundCnt = refundCnt
        },
        async onClick(btn, idx) {
            btn.active = !btn.active

            if (this.step === 0) {
                if (idx === 0) {
                    try {
                        this.$loading(true)
                        await realFriendService.confirmMeeting(this.introduce.id, this.introduce.request.id, {
                            meeting_status: 'meeting',
                        })

                        const updatedChat = { ...this.chat }
                        updatedChat.introduce.request.accept_status = 'meeting'
                        this.$store.commit('updateChatAndChats', updatedChat)
                    } catch (e) {
                        console.error(e)
                    } finally {
                        setTimeout(() => {
                            this.$stackRouter.pop()
                        }, 500)
                        this.$loading(false)
                    }
                } else {
                    setTimeout(() => {
                        this.step += 1
                    }, 500)
                }
            } else if (this.step === 1) {
                if (idx === 0) {
                    const index = await this.$modal.basic({
                        title: '환급 신청',
                        body: `
                            <p class="m-b-6">
                                <span class="c-pink-deep f-medium">만나지 않았고, 더 이상 만날 예정이 없는 경우</span>에만 환급 신청이 가능합니다.
                            </p>
                            <p class="c-text-light">
                                (환급 신청하면, 주선자에게 만남 의사가 없다고 전달됩니다.)
                            </p>`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: '신청',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (index === 1) {
                        const messages = this.chat.$$messages

                        const acceptIntroduceMessage = messages.find(m => m.mtype === 'accept-introduce')
                        this.$store.commit('setRefundType', 'realFriend')
                        this.$store.commit('setPayloads', {
                            refund: {
                                dating: {
                                    id: this.introduce.id,
                                    request_id: this.introduce.request.id,
                                    name: this.$mustParse(acceptIntroduceMessage.content).name,
                                    agent_name: this.chat.user.name,
                                    agent_nickname: this.chat.user.nickname,
                                    created_at: acceptIntroduceMessage.created_at,
                                    refund_cnt: this.refundCnt,
                                },
                            },
                        })

                        this.$router.push({ name: 'Refund1Page' })

                        this.$stackRouter.clear()
                    }
                } else if (idx === 1) {
                    this.$modal
                        .basic({
                            body: '좋은 만남이 되길 바라요! 😆<br />만남 후에 꼭 다시 알려주세요!',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(() => {
                            this.$stackRouter.pop()
                        })
                }
            }
        },
        leftButtonHandler() {
            if (this.step === 1) this.step -= 1
            else {
                this.$stackRouter.pop()
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.real-friend-meeting-confirm {
    color: black;

    .title {
        font-size: 24px;
        font-weight: 500;
        line-height: normal;

        @include f-medium;
    }

    .btns {
        @include flex;

        .btn {
            height: 56px;
            background-color: $grey-01;
            justify-content: space-between;
            font-size: 16px;
            border-radius: 12px;

            @include f-medium;

            .material-icons {
                color: $grey-04;
            }
        }

        .btn.active {
            background-color: $purple-primary;
            color: white;

            .material-icons {
                color: white;
            }
        }
    }
}
</style>
